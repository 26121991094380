import React from 'react'
import { Link } from 'react-router-dom'
import './About_Me.css'
import Fade from 'react-reveal/Fade';
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";

export default function About_Me() {

  return (
    <Fade right>
      <div className="about_me_container">
        <div className="description-container">
          <h3 id="description-text">
            I am a <span className="red-text">Full-Stack Developer</span> with a naturally curious demeanor.
            I bring a decade of experience in operations and logistics,
            making me <span className="green-text">uniquely suited</span> to logically deconstructing and
            <span className="yellow-text"> solving programming problems</span>. I am patient, dedicated, capable
            of integrating into different teams and contributing to their <span className="green-text">overall success</span>.
          </h3>
        </div>
        <div className="button-container">
          <Link to="/aboutme/skills" style={{ textDecoration: 'none'}}>
            <button className="about-me-button">
              <h3 className="button-text-skills" style={{color: 'rgb(64,64,64)'}}>Skills</h3>
              <IoIosArrowDropleft className="arrow" size="5em" style={{color: 'rgb(64,64,64)'}}/>
            </button>
          </Link>
          <Link to="/aboutme/education" style={{ textDecoration: 'none'}}>
            <button className="about-me-button">
              <h3 className="button-text" style={{color: 'rgb(64,64,64)'}}>Education</h3>
              <IoIosArrowDropdown className="arrow" size="5em" style={{color: 'rgb(64,64,64)'}}/>
            </button>
          </Link>
          <Link to="/aboutme/experience" style={{ textDecoration: 'none'}}>
            <button className="about-me-button">
              <h3 className="button-text" style={{color: 'rgb(64,64,64)'}}>Experience</h3>
              <IoIosArrowDropright className="arrow" size="5em" style={{color: 'rgb(64,64,64)'}}/>
            </button>
          </Link>
        </div>
      </div>
    </Fade>
  )
}