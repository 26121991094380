import React from 'react'
import { Link } from 'react-router-dom'
import { stack as Menu } from 'react-burger-menu'
import './Sidebar.css'

export default props => {
  return (
    <Menu>
      <Link to='/' className="menu-item">Home</Link>
      <Link to="/aboutme" className="menu-item">About Me</Link>
      <Link to='/aboutme/skills' className="menu-item" className="resume-pc" id="skills">- Skills</Link>
      <Link to='/aboutme/experience' className="menu-item" className="resume-pc" id="experience">- Experience</Link>
      <Link to='/aboutme/education' className="menu-item" className="resume-pc" id="education">- Education</Link>
      <Link to='/projects' className="menu-item">My Projects</Link>
      <Link to='/projects/meshedfamily' className="menu-item" className="project_name" id="Meshed">- Meshed Up Family</Link>
      <Link to='/projects/nurseline' className="menu-item" className="project_name" id="Nurseline">- Nurseline</Link>
      <Link to='/projects/mypoetrycollection' className="menu-item" className="project_name" id="MyPoetryCollection">- MyPoetryCollection</Link>
      <Link to="/contactme"className="menu-item">My Blog</Link>
      <Link to="/contactme" className="menu-item">Contact Me</Link>
    </Menu>
  )
}