import React from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import Fade from 'react-reveal/Fade';
import meshed from '../images/meshed.png';
import nurseline from '../images/nurseline.png';
import mypoetrycollection from '../images/mypoetrycollection.png';

export default function Projects() {

  return (
    <Fade top>
      <div className="projects_container">
        <div className="project1">
          <Link to='/projects/meshedfamily'>
            <button className="project-button">
              <h1 className="project-name">Meshed Family</h1>
            </button>
          </Link>
          <img src={meshed} className="website-image" alt="Meshed website capture" />
        </div>
        <div className="project2">
          <Link to='/projects/nurseline'>
            <button className="project-button">
              <h1 className="project-name">Nurseline</h1>
            </button>
          </Link>
          <img src={nurseline} className="website-image" alt="Nurseline website capture"/>
        </div>
        <div className="project3">
          <Link to="/projects/mypoetrycollection">
            <button className="project-button">
              <h1 className="project-name">MyPoetryCollection</h1>
            </button>
          </Link>
          <img src={mypoetrycollection} className="website-image" alt="Nurseline website capture"/>
        </div>
        <div id="projects-title">
          <h1 className="pro-title">Projects</h1>
        </div>
      </div>
    </Fade>
  )
}
