import React from 'react'
import './Skills.css'
import Fade from 'react-reveal/Fade';
import { FaHtml5 } from 'react-icons/fa'
import { DiCss3 } from 'react-icons/di'
import { DiJavascript } from 'react-icons/di'
import { GrReactjs } from 'react-icons/gr'
import { IoLogoNodejs } from 'react-icons/io'
import { BsBootstrap } from 'react-icons/bs'
import { DiRuby } from 'react-icons/di'
import { SiPostgresql } from 'react-icons/si'
import { DiGit } from 'react-icons/di'
import { SiRails } from 'react-icons/si'
import { DiMongodb } from 'react-icons/di'
import { SiMaterialUi } from 'react-icons/si'
import {FaGithubSquare} from 'react-icons/fa'
import { SiMicrosoftazure } from 'react-icons/si'
import { SiRedux } from 'react-icons/si'

export default function Skills() {

  return (
    <Fade right>
      <div className="skills-container">
        <h1 id="skills-title">Skills</h1>
        <div>
          <FaHtml5 className="desktop_skills_logo" size="2.8em" style={{ color: 'white' }} />
          <FaHtml5 className="mobile_skills_logo" size="2em" style={{ color: 'white' }} />
          <h4 className="skill-name">HTML5</h4>
        </div>
        <div>
          <DiCss3 className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <DiCss3 className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">CSS3</h4>
        </div>
        <div>
          <DiJavascript className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <DiJavascript className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">JAVASCRIPT</h4>
        </div>
        <div>
          <GrReactjs className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <GrReactjs className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">REACTJS</h4>
        </div>
        <div>
          <SiRedux className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <SiRedux className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">REDUX</h4>
        </div>
        <div>
          <BsBootstrap className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <BsBootstrap className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">BOOTSTRAP</h4>
        </div>
        <div>
          <DiRuby className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <DiRuby className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">RUBY</h4>
        </div>
        <div>
          <SiPostgresql className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <SiPostgresql className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">POSTGRESSQL</h4>
        </div>
        <div>
          <DiGit className="desktop_skills_logo" size="4em" style={{ color: 'white' }} />
          <DiGit className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">GIT</h4>
        </div>
        <div>
          <SiRails className="desktop_skills_logo" size="4em" style={{ color: 'white' }} />
          <SiRails className="mobile_skills_logo" size="3.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">RUBY ON RAILS</h4>
        </div>
        <div>
          <DiMongodb className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <DiMongodb className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">MOONGODB</h4>
        </div>
        <div>
          <SiMaterialUi className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <SiMaterialUi className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">MATERIAL UI</h4>
        </div>
        <div>
          <FaGithubSquare className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <FaGithubSquare className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">GITHUB</h4>
        </div>
        <div>
          <SiMicrosoftazure className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <SiMicrosoftazure className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">MS AZURE</h4>
        </div>
        <div>
          <IoLogoNodejs className="desktop_skills_logo" size="3em" style={{ color: 'white' }} />
          <IoLogoNodejs className="mobile_skills_logo" size="2.2em" style={{ color: 'white' }} />
          <h4 className="skill-name">NODEJS</h4>
        </div>
      </div>
    </Fade>
  )
}