import React from 'react'
import Fade from 'react-reveal/Fade';
import './Home.css'

export default function Home() {

  return (
    <div className="home_page_container">
      <Fade top>
        <h1 className="logo">{"<K.Khan/>"}</h1>
      </Fade>
      <Fade bottom>
        <h3 className="personal_intro">Greetings. My name is Kashiff, a full-stack developer.</h3>
      </Fade>
    </div>
  )
}