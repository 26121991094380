import React from 'react';
import './Experience.css'
import kkhan from '../images/kkhan.png'
import galogo from '../images/galogo.png'
import Fade from 'react-reveal/Fade';
import {GrHistory} from 'react-icons/gr'

export default function Experience() {
  return (
    <Fade left>
      <div id='experience-container'>
        <div className="logo-div">
          <img src={kkhan} alt="KKhan Dev" id="kkhanlogo" />
          <h1 className="exp-title">KKhan Dev <br/>(Dec 2020 - Current)</h1>
          <h2 className="client-name">Freelance Developer; developing apps for houses
          of worship, travel agencies, and real estate professionals</h2>
        </div>
        <div className="logo-div">
          <img src={galogo} alt="GA Logo" id="ga-logo" />
          <h1 className="exp-title">General Assembly <br/>(Sep 2020 - Dec 2020)</h1>
          <h2 className="client-name">Full-time fellow in a Software Engineer Immersive program with over 400 hours of professional
          training in building full-stack responsive web apps. Collaborated with UX teams to
          build and better projects. Gained experience in creating and working with APIs.</h2>
        </div>
        <div className="logo-div">
          <GrHistory size="12em" />
          <h1 className="exp-title">Before Code</h1>
          <h2>Operations Manager at Ross Stores</h2>
          <h2>Multi-Unit Ops Manager at Macys</h2>
          <h2>Store Director at Target Corp</h2>
        </div>
        <h1 id="experience-title">Experience</h1>
      </div>
      <div id="mobile-experience-container">
        <div id="mobile-kkhan-exp">
          <img src={kkhan} id="mobile-kkhan-logo"/>
          <h2 className="mobile-desc">Freelance Developer <br/> (Dec 2020-Current).</h2>
        </div>
        <div id="mobile-ga-exp">
          <img src={galogo} id="mobile-ga-logo"/>
          <h2 className="mobile-desc">Full-time fellow in a Software Engineer Immersive program. <br/> (Sep 2020 - Dec 2020)</h2>
        </div>
        <div id="mobile-precode-exp">
          <GrHistory size="3em" />
          <h2 className="mobile-desc">Before Code. <br/>Logistics & Operations Manager.</h2>
        </div>
        <div id="mobile-experience-title"><h1>Experience</h1></div>
      </div>
    </Fade>
  );
}