import React from 'react'
import './Education.css'
import Fade from 'react-reveal/Fade';
import galogo from '../images/galogo.png'
import ga2 from '../images/ga2.png'
import rutgers from '../images/rutgers.png'
import rutgers2 from '../images/rutgers2.png'

export default function Education() {

  return (
    <Fade top>
      <div className="education-container">
        <div className="ga-education">
          <img src={galogo} className="galogo" />
          <h1 className="ga-program-title">Software Engineering Immersive Fellowship</h1>
          <h1 className="ga-certificate">Cerificate of Completion</h1>
          <img src={ga2} className="galogo2" />
        </div>
        <div className="rutgers-education">
          <img src={rutgers2} className="rutgerslogo"/>
          <h1 className="rutgers-program-title">English Literature & Sociology</h1>
          <h1 className="rutgers-program-title">Bachelor of Arts</h1>
          <img src={rutgers} className="rutgerslogo" />
        </div>
      </div>
    </Fade>
  )
}