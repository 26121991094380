import './App.css';
import { Switch, Route, useHistory } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import Home from './home/Home'
import About_Me from './aboutme/About_Me';
import Projects from './projects/Projects';
import Education from './education/Education';
import Skills from './skills/Skills';
import Experience from './experience/Experience';
import Meshed from './meshed/Meshed';
import Nurseline from './nurseline/Nurseline';
import MyPoetryCollection from './mypoetrycollection/MyPoetryCollection'

function App() {
  return (
    <div className="App" id="outer-container">
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/>
      <div id="page-wrap">
        <Switch>
          <Route exact path='/'><Home/></Route>
          <Route exact path='/aboutme'><About_Me /></Route>
          <Route exact path='/projects'><Projects /></Route>
          <Route path='/aboutme/education'><Education /></Route>
          <Route path='/aboutme/skills'><Skills /></Route>
          <Route path='/aboutme/experience'><Experience /></Route>
          <Route path='/projects/meshedfamily'><Meshed /></Route>
          <Route path='/projects/nurseline'><Nurseline /></Route>
          <Route path='/projects/mypoetrycollection'><MyPoetryCollection/></Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
