import React from 'react';
import './MyPoetryCollection.css';
import mypoetrycollection from '../images/mypoetrycollection.png';
import Fade from 'react-reveal/Fade';
import { FaHtml5 } from 'react-icons/fa';
import { DiCss3 } from 'react-icons/di';
import { GrReactjs } from 'react-icons/gr';
import { DiJavascript } from 'react-icons/di';

export default function Nurseline() {
  return (
    <Fade top>
      <div id="mypoetrycollection-container">
        <div>
          <img src={mypoetrycollection} className="mypoetrycollection-project-image" />
          <h1 className="mypoetrycollection-tech-stack-title">Tech Stack</h1>
          <div className="mypoetrycollection-tech-stack">
            <FaHtml5 size="4em" style={{ color: 'white' }} />
            <DiCss3 size="4em" style={{ color: 'white' }} />
            <DiJavascript size="4em" style={{ color: 'white' }} />
            <GrReactjs size="4em" style={{ color: 'white' }} />
          </div>
          <div className="mypoetrycollection-proj-buttons">
            <a href={'https://agitated-northcutt-4a166c.netlify.app/'} target="_blank" style={{ textDecoration: 'none' }}><h3 className="test" >Visit App</h3></a>
            <a href={'https://github.com/Kashiff11/Meshed'} target="_blank" style={{ textDecoration: 'none' }}><h3 className="test">Github Repo</h3></a>
          </div>
        </div>
        <div id="mypoetrycollection-app-desc">
          <h1>
            MyPoetryCollection is an app created for poetry enthusiasts. I incorporated authorization using Ruby on Rails. Databases
            were created using PostgresSQL and frontend was creates using ReactJS.
          </h1>
        </div>
        <div className="project-mypoetrycollection-title-div">
          <h1 className="project-mypoetrycollection-title">Nurseline</h1>
        </div>
      </div>
      <div id="mobile-mypoetrycollection-container">
        <h2 id="mypoetrycollection-mobile-desc">MyPoetryCollection is an app created for poetry enthusiasts. I incorporated authorization using Ruby on Rails. Databases
            were created using PostgresSQL and frontend was creates using ReactJS.</h2>
        <img src={mypoetrycollection} className="mypoetrycollection-project-image" />
        <h1 className="mypoetrycollection-tech-stack-title">Tech Stack</h1>
        <div className="mypoetrycollection-tech-stack">
          <FaHtml5 size="3em" style={{ color: 'white' }} />
          <DiCss3 size="3em" style={{ color: 'white' }} />
          <DiJavascript size="3em" style={{ color: 'white' }} />
          <GrReactjs size="3em" style={{ color: 'white' }} />
        </div>
        <div className="mypoetrycollection-proj-buttons">
          <a href={'https://nifty-bohr-a8b873.netlify.app/'} target="_blank" style={{ textDecoration: 'none' }}><h3 className="test" >Visit App</h3></a>
          <a href={'https://github.com/Kashiff11/MyPoemsCollection.com'} target="_blank" style={{ textDecoration: 'none' }}><h3 className="test">Github Repo</h3></a>
        </div>
        <h1 className="mypoetrycollection-lower-title">MyPoetryCollection</h1>
      </div>
    </Fade>
  );
}