import React from 'react';
import './Nurseline.css';
import nurseline from '../images/nurseline.png';
import Fade from 'react-reveal/Fade';
import { FaHtml5 } from 'react-icons/fa';
import { DiCss3 } from 'react-icons/di';
import { GrReactjs } from 'react-icons/gr';
import { DiJavascript } from 'react-icons/di';

export default function Nurseline() {
  return (
    <Fade top>
      <div id="nurseline-container">
        <div>
          <img src={nurseline} className="nurseline-project-image" />
          <h1 className="nurseline-tech-stack-title">Tech Stack</h1>
          <div className="nurseline-tech-stack">
            <FaHtml5 size="4em" style={{ color: 'white' }}/>
            <DiCss3 size="4em" style={{ color: 'white' }}/>
            <DiJavascript size="4em" style={{ color: 'white' }}/>
            <GrReactjs size="4em" style={{ color: 'white' }}/>
          </div>
          <div className="nurseline-proj-buttons">
            <a href={'https://agitated-northcutt-4a166c.netlify.app/'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test" >Visit App</h3></a>
            <a href={'https://github.com/Kashiff11/Meshed'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test">Github Repo</h3></a>
          </div>
        </div>
        <div id="nurseline-app-desc">
          <h1>
            Nurseline is a social media app created for nurses. It was built in conjunction with a talented UX design team.
            Backend created with Express and data organized using MongoDB. 
          </h1>
        </div>
        <div className="project-nurseline-title-div">
          <h1 className="project-nurseline-title">Nurseline</h1>
        </div>
      </div>
      <div id="mobile-nurseline-container">
        <h2 id="nurseline-mobile-desc">Nurseline is a social media app created for nurses. It was built in conjunction with a talented UX design team.
            Backend created with Express and data organized using MongoDB.</h2>
        <img src={nurseline} className="nurseline-project-image" />
        <h1 className="nurseline-tech-stack-title">Tech Stack</h1>
        <div className="nurseline-tech-stack">
          <FaHtml5 size="3em" style={{ color: 'white' }}/>
          <DiCss3 size="3em" style={{ color: 'white' }}/>
          <DiJavascript size="3em" style={{ color: 'white' }}/>
          <GrReactjs size="3em" style={{ color: 'white' }}/>
        </div>
        <div className="nurseline-proj-buttons">
          <a href={'https://www.nurseline.netlify.app/sign-in'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test" >Visit App</h3></a>
          <a href={'https://github.com/MakerMacAttack/nurse-line'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test">Github Repo</h3></a>
        </div>
        <h1 className="nurseline-lower-title">Nurseline</h1>
      </div>
    </Fade>
  );
}