import React from 'react';
import './Meshed.css';
import meshed from '../images/meshed.png';
import Fade from 'react-reveal/Fade';
import { FaHtml5 } from 'react-icons/fa';
import { DiCss3 } from 'react-icons/di';
import { GrReactjs } from 'react-icons/gr';
import { DiJavascript } from 'react-icons/di';

export default function Meshed() {
  return (
    <Fade top>
      <div id="meshed-container">
        <div>
          <img src={meshed} className="project-image" />
          <h1 className="tech-stack-title">Tech Stack</h1>
          <div className="tech-stack">
            <FaHtml5 size="4em" style={{ color: 'white' }}/>
            <DiCss3 size="4em" style={{ color: 'white' }}/>
            <DiJavascript size="4em" style={{ color: 'white' }}/>
            <GrReactjs size="4em" style={{ color: 'white' }}/>
          </div>
          <div className="proj-buttons">
            <a href={'https://agitated-northcutt-4a166c.netlify.app/'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test" >Visit App</h3></a>
            <a href={'https://github.com/Kashiff11/Meshed'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test">Github Repo</h3></a>
          </div>
        </div>
        <div id="app-desc">
          <h1>Meshed Family is an app that families can use to create and organize
          to-do lists. Tasks are created and organized by family member and category.
          All data is held in an API created using Airtable.com</h1>
        </div>
        <div className="project-meshed-title-div">
          <h1 className="project-meshed-title">Meshed Family</h1>
        </div>
      </div>
      <div id="mobile-meshed-container">

        <h2 id="mobile-desc">Meshed Family is an app that families can use to create and organize
        to-do lists. Tasks are created and organized by family member and category.
        All data is held in an API created using Airtable.com</h2>
        <img src={meshed} className="project-image" />
        <h1 className="tech-stack-title">Tech Stack</h1>
        <div className="tech-stack">
          <div>
            <FaHtml5 className="meshed-dt-logo" size="3em" style={{ color: 'white' }} />
            <FaHtml5 className="meshed-mobile-logo" size="2em" style={{ color: 'white' }} />
          </div>
          <div>
            <DiCss3 className="meshed-dt-logo" size="3em" style={{ color: 'white' }} />
            <DiCss3 className="meshed-mobile-logo" size="2.2em" style={{ color: 'white' }} />
          </div>
          <div>
            <DiJavascript className="meshed-dt-logo" size="3em" style={{ color: 'white' }} />
            <DiJavascript className="meshed-mobile-logo" size="2em" style={{ color: 'white' }} />
          </div>
          <div>
            <GrReactjs className="meshed-dt-logo" size="3em" style={{ color: 'white' }} />
            <GrReactjs className="meshed-mobile-logo" size="2em" style={{ color: 'white' }}/>
          </div>
        </div>
        <div className="proj-buttons">
          <a href={'https://agitated-northcutt-4a166c.netlify.app/'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test" >Visit App</h3></a>
          <a href={'https://github.com/Kashiff11/Meshed'} target="_blank" style={{ textDecoration: 'none'}}><h3 className="test">Github Repo</h3></a>
        </div>
         <h1 className="lower-title">Meshed</h1>
      </div>
    </Fade>
  );
}